@import 'Colours';

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.6em;
  font-weight: 500;
  font-size: 1em;
  font-family: inherit;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  padding: 5px 25px;
  transition: background-color 0.1s, color 0.1s, border-color 0.1s;
  --btn-primary: #{$payment-source-blue};
  --btn-secondary: white;
  --btn-hover: #{$payment-source-dark-blue};

  .fastSpin {
    -webkit-animation: fa-spin 0.5s infinite linear;
    -moz-animation: fa-spin 0.5s infinite linear;
    animation: fa-spin 0.5s infinite linear;
  }

  &[disabled] {
    pointer-events: none;
  }

  &.disable {
    opacity: 0.7;
  }

  border: 0.18rem solid var(--btn-primary);

  &.solid {
    background: var(--btn-primary);
    color: var(--btn-secondary);

    &:hover {
      background-color: var(--btn-hover);
      border-color: var(--btn-hover);
    }
  }

  &.outline {
    background: transparent;
    color: var(--btn-primary);

    &:hover {
      background-color: var(--btn-primary);
      border-color: var(--btn-primary);
      color: var(--btn-secondary);
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #27245b81;
  }
}

.linkBtn {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  color: $payment-source-blue;
  font-weight: 500;
  min-width: auto;
  padding: 5px 0;

  &:focus-visible {
    outline: 2px solid black;
    border-radius: 3px;
  }
  &:hover {
    text-decoration: underline !important;
  }



}