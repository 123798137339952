$payment-source-blue: #00444d;
$payment-source-dark-blue: #002D33;

$payment-source-green: #97c93d;

$report-light-grey: #ECECEC;

$payment-source-primary-background: #ffffff;
$payment-source-secondary-background: #fafafa;

$border-light-gray: #c8c8c8;
$nav-light-gray: #cbcbcb;
$background-gray: #f3f3f3;

$state-success: #28a745;
$state-success-shadow: rgba(25, 135, 84, 0.386);
$state-warning: #a16500;
$state-warning-shadow: #a1650023;
$state-danger: #dc3545;
$state-danger-shadow: #dc354623;
$state-info: #00AEEF;

// --------- Payment Status colors ---------
$paymentStatus-paid-bg: #e8f5e9;
$paymentStatus-paid-bd: #4caf50;
$paymentStatus-staged-bg: #e3edfd;
$paymentStatus-staged-bd: #219bf3;
$paymentStatus-exception-bg: #fff79b;
$paymentStatus-exception-bd: #ffeb3b;
$paymentStatus-failed-bg: #ffebee;
$paymentStatus-failed-bd: #f44336;
$paymentStatus-declined-bg: #fbe9e7;
$paymentStatus-declined-bd: #ff5722;
$paymentStatus-cancelled-bg: #f5f5f5;
$paymentStatus-cancelled-bd: #9e9e9e;
$paymentStatus-processing-bg: #fffde7;
$paymentStatus-processing-bd: #ffeb3b;
$paymentStatus-expired-bg: #efebe9;
$paymentStatus-expired-bd: #795548;
$paymentStatus-refunded-bg: #fff1c8;
$paymentStatus-refunded-bd: #ffc107;
$paymentStatus-refundPending-bg: #fffde7;
$paymentStatus-refundPending-bd: #ffeb3b;
$paymentStatus-refundMultiple-bg: #fff1c8;
$paymentStatus-refundMultiple-bd: #ffc107;
$paymentStatus-refundCancelled-bg: #f5f5f5;
$paymentStatus-refundCancelled-bd: #9e9e9e;
$paymentStatus-testMode-bg: #f5f5f5;
$paymentStatus-testMode-bd: #9e9e9e;
$paymentStatus-unknown-bg: #f5f5f5;
$paymentStatus-unknown-bd: #9e9e9e;

// --------- Payout Status colors ---------
$payoutStatus-netZero-bg: #e8f5e9;
$payoutStatus-netZero-bd: #4caf50;
$payoutStatus-pending-bg: #fffde7;
$payoutStatus-pending-bd: #ffeb3b;
$payoutStatus-sent-bg: #e3fdfa;
$payoutStatus-sent-bd: #21c1f3;
$payoutStatus-failed-bg: #ffebee;
$payoutStatus-failed-bd: #f44336;
$payoutStatus-rejected-bg: #ffebee;
$payoutStatus-rejected-bd: #f44336;
$payoutStatus-completed-bg: #e8f5e9;
$payoutStatus-completed-bd: #4caf50;
$payoutStatus-unknown-bg: #f5f5f5;
$payoutStatus-unknown-bd: #9e9e9e;
