@import 'Colours';

html,
body {
  font-family: 'Rubik', sans-serif;
  background-color: $payment-source-primary-background;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  &:hover {
    background: #555;
  }
}

*:not(ol, ul) {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#main-cont,
#main,
#nav-bar,
.footer,
#payment-form,
#review-cart {
  outline: none;
}

.fa-spin.fastSpin {
  -webkit-animation: fa-spin 0.5s infinite linear;
  -moz-animation: fa-spin 0.5s infinite linear;
  animation: fa-spin 0.5s infinite linear;
}

#main-cont {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#main {
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  position: relative;
}

.maxWidth16 {
  max-width: 1600px;
  margin: 0 auto;
}

input {
  font-family: inherit;
}

select {
  font-family: inherit;
}

.visuallyHidden {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.renderedList {
  margin-top: 5px;
  padding-left: 25px;
  font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h4 {
  font-size: 1.1em;
}

.sentenceHighlight {
  font-weight: 500;
  display: inline;
}

a {
  color: $payment-source-blue;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: $payment-source-dark-blue;
    text-decoration: underline;
  }
}

.container {
  margin: 30px auto;
  width: 100%;
  padding: 0 10px;
}

.narrowPageContainer {
  @extend .container;
  max-width: 50em;
}

.widePageContainer {
  @extend .container;
  max-width: 85%;

  @media only screen and (max-width: 768px) {
    max-width: 100vw;
  }
}

.processPageContainer {
  max-width: 1200px;
  margin: 30px auto;
}

.startPageContainer {
  max-width: 47em;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
}

.loadingAnimation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 20px;

  @media only screen and (max-width: 768px) {
    padding: 40px 20px;
  }
}

@media only print {
  .printingAgreement {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    visibility: hidden;

    nav,
    .skipCont,
    #widget-button {
      display: none !important;
    }

    .printOnly {
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: auto;
      overflow-y: none;
      margin: 0 0;
      border: none;
    }
  }
}

// fixes address auto complete on small screens
.pca .pcaitem, .pca .pcaselected {
  text-wrap: pretty;
}

#widget-button {
  display: none !important;
}

#skip-to-nav-div {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, 100%);
  transition: transform 0.3s;
  text-align: center;
  background-color: $payment-source-blue;
  padding: 5px 3px;
  display: inline-block !important;
  border-radius: 5px;
  margin-left: 2px;
  &:focus-within {
    transform: translate(-50%, 10%);
  }
  button {
    background-color: transparent;
    color: white;
    padding: 5px;
    margin: 0 2.5px;
    border-radius: 5px;
    border: 1px solid transparent !important;
    font-size: 1.25em;
    font-weight: normal;

      &:focus {
        border: 1px solid #ffff !important;
      }
      &:hover {
        background-color: $payment-source-dark-blue;
      }
  }
}

.backButton {
  margin: 20px 0 40px 0;
  display: inline-block;

  span {
    margin-left: 5px;
  }
}

.backButtonNarrow {
  @extend .backButton;
  margin: 0 0 20px 0;
}

.tooltipText {
  white-space: pre-wrap;
  visibility: hidden;
  max-width: 17rem;
  background-color: $background-gray;
  border: 1px solid $border-light-gray;
  color: black;
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  z-index: 6;
  font-size: 0.9rem;

  &.tooltipVisible {
    visibility: visible;
  }

  p {
    font-weight: 500;
  }

  > p:not(:first-child) {
    margin-top: 15px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: $border-light-gray;
  }
}

// react-data-grid styles

.rdg-top-summary-row .rdg-cell {
  border-bottom: 1px solid var(--rdg-border-color);
  border-right: none;
}

// End of react-data-grid styles