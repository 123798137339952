@import 'Colours.scss';

.footer {
  background-color: $payment-source-dark-blue;

  .miniFooter,
  .miniFooterFrench {
    max-width: 1570px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-left: 1.5rem;
    align-items: center;

    a {
      color: white;
    }

    button.miniFooterLink,
    a.miniFooterLink {
      opacity: 0.8 !important;
      color: white !important;
      margin-right: 25px !important;
      padding: 2px;

      &:focus-visible {
        outline: 1px solid white !important;
        border-radius: 2px;
      }

      &:hover {
        opacity: 1 !important;
        text-decoration: none !important;
      }

      @media only screen and (max-width: 300px) {
        margin-right: 0 !important;
        text-align: left;

        &:not(:last-child) {
          margin-bottom: 10px !important;
        }
      }
    }

    .footerLinks {
      display: flex;

      @media only screen and (max-width: 300px) {
        flex-direction: column;
      }
    }
  }

  p {
    color: white;
  }



  .copyrightText {
    padding: 0;
    margin: 0;
    font-size: 0.7em;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
  }





  @media only screen and (max-width: 800px) {
    .miniFooter {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;

      p {
        margin-top: 10px;
      }
    }
  }

  @media only screen and (max-width: 1070px) {
    .miniFooterFrench {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;

      p {
        margin-top: 10px;
      }
    }
  }

}